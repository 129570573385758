<template>
  <div class="relative flex items-center">
    <div
      v-if="$slots.icon"
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center py-3 pl-3 pr-2"
      aria-hidden="true"
    >
      <slot name="icon" />
    </div>
    <input v-model="model" v-bind="$attrs" :class="inputStyles" />
    <div v-if="$slots.action" class="absolute right-0 flex items-center">
      <slot name="action" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { InputHTMLAttributes } from "vue";

defineOptions({
  inheritAttrs: false,
});

export interface BaseInputProps extends /* @vue-ignore */ InputHTMLAttributes {
  outline?: "primary" | "white" | "black";
}

const props = withDefaults(defineProps<BaseInputProps>(), {
  outline: "primary",
});

const model = defineModel<string | number>();

const slots = useSlots();

const baseStyles = [
  "label-base block w-full border-0 bg-neutral-100 py-3 placeholder:text-neutral-400",
  "focus:outline-none focus:ring-[3px] focus:ring-inset disabled:pointer-events-none",
];

const outlineStyles = {
  primary: "focus:ring-blue-700",
  white: "focus:ring-white",
  black: "focus:ring-black",
};

const inputStyles = [
  baseStyles,
  slots.icon ? "pl-11" : "pl-3",
  outlineStyles[props.outline],
];
</script>
